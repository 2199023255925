export const ROTARY_ASSISTANCE_EMAIL = 'data@rotary.org';

export const ROTARY_URL = 'https://my.rotary.org';

export const SUPPLIER_PORTAL_URL = 'https://www.rotary.org/SupplierPortal';

export const DATA_CORRECTIONS_EMAIL = 'datacorrections@rotary.org';

export const DISASTER_RESPONSE_GRANTS_URL =
  'https://my-cms.rotary.org/en/take-action/apply-grants/rotary-disaster-response-grants';

export const ROTARY_FOUNDATION_URL =
  'https://www.rotary.org/en/about-rotary/rotary-foundation';

export const MY_CMS_URL_PROD = 'https://my-cms.rotary.org/';
export const MY_CMS_URL_UAT = 'https://my-cmsuat.rotary.org/';
export const MY_CMS_URL_QAT = 'https://my-cmsqat.rotary.org/';
export const MY_CMS_URL_DEV = 'https://my-cmsdev.rotary.org/';
export const MY_CMS_URL_COMPOSE = 'http://my-cms.rotary.local:8080/';

export const DONATE_WIDGET_URL_PROD =
  'https://my-cms.rotary.org/sites/all/modules/custom/rotary_donate_widget/donateWidget.js';
export const DONATE_WIDGET_URL_UAT =
  'https://my-cmsuat.rotary.org/sites/all/modules/custom/rotary_donate_widget/donateWidget.js';
export const DONATE_WIDGET_URL_COMPOSE =
  'http://my-cms.rotary.local:8080/sites/all/modules/custom/rotary_donate_widget/donateWidget.js';

export const DRUPAL_ENTITY_ID = 34;

export enum DONATE_WIDGET_MODES {
  GIV = 'giv',
  DRF = 'drf',
  RD = 'rotary-direct',
  EP = 'ep',
  INVOICE = 'invoice',
  SS = 'ss',
}

export const DONATE = 'donate';
export const GIVE_GIFT_ROTARY = 'give-gift-rotary';
export const GIVING_TUESDAY = 'giving-tuesday';
export const DISASTER_RESPONSE_FUND = 'disaster-response-fund';
export const ROTARY_DIRECT = 'rotary-direct';
export const CLUB_SEARCH = 'club-search';
export const POLIO_PLUS_FUND = 'polioplus-fund';
export const CLUB_INVOICE = 'club-invoice';
export const SS = 'self-service';

export const WIDGET_DESTINATION_PATHS = [
  DONATE,
  GIVE_GIFT_ROTARY,
  GIVING_TUESDAY,
  DISASTER_RESPONSE_FUND,
  ROTARY_DIRECT,
  POLIO_PLUS_FUND,
  CLUB_INVOICE,
];

export const REFRESH_DESTINATION_PATHS = [
  ...WIDGET_DESTINATION_PATHS,
  CLUB_SEARCH,
];

export const ROTARY_INTERNATIONAL_HEADQUARTERS_URL =
  'https://my.rotary.org/en/visit-rotary-international-world-headquarters/';
export const ROTARY_INTERNATIONAL_OFFICES_URL =
  'https://www.rotary.org/en/about-rotary/our-leaders/international-offices/';
export const ROTARY_GREAT_BRETAIN_AND_IRELAND_URL =
  'https://www.rotarygbi.org/';
export const ROTARY_CLUB_AND_DISTRICT_SUPPORT_REPRESENTATIVES_URL =
  'https://my.rotary.org/en/contact/representatives/';
export const ROTARY_FINANCIAL_REPRESENTATIVES_URL =
  'https://my.rotary.org/en/contact/representatives/';
